var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vtl" }, [
    _vm.model.name !== "root"
      ? _c(
          "div",
          {
            staticClass: "vtl-node",
            class: {
              "vtl-leaf-node": _vm.model.isLeaf,
              "vtl-tree-node": !_vm.model.isLeaf
            },
            attrs: { id: _vm.model.id }
          },
          [
            _c("div", {
              staticClass: "vtl-border vtl-up",
              class: { "vtl-active": _vm.isDragEnterUp },
              on: {
                drop: _vm.dropBefore,
                dragenter: _vm.dragEnterUp,
                dragover: _vm.dragOverUp,
                dragleave: _vm.dragLeaveUp
              }
            }),
            _c(
              "div",
              {
                class: _vm.treeNodeClass,
                attrs: { draggable: !_vm.model.dragDisabled },
                on: {
                  dragstart: _vm.dragStart,
                  dragover: _vm.dragOver,
                  dragenter: _vm.dragEnter,
                  dragleave: _vm.dragLeave,
                  drop: _vm.drop,
                  dragend: _vm.dragEnd,
                  mouseover: _vm.mouseOver,
                  mouseout: _vm.mouseOut,
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.click($event)
                  }
                }
              },
              [
                _vm.model.children && _vm.model.children.length > 0
                  ? _c("span", { staticClass: "vtl-caret vtl-is-small" }, [
                      _c("i", {
                        staticClass: "vtl-icon",
                        class: _vm.caretClass,
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.toggle($event)
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                _vm.model.isLeaf
                  ? _c(
                      "span",
                      [
                        _vm._t(
                          "leafNodeIcon",
                          [
                            _c("i", {
                              staticClass:
                                "vtl-icon vtl-menu-icon vtl-icon-file"
                            })
                          ],
                          {
                            expanded: _vm.expanded,
                            model: _vm.model,
                            root: _vm.rootNode
                          }
                        )
                      ],
                      2
                    )
                  : _c(
                      "span",
                      [
                        _vm._t(
                          "treeNodeIcon",
                          [
                            _c("i", {
                              staticClass:
                                "vtl-icon vtl-menu-icon vtl-icon-folder"
                            })
                          ],
                          {
                            expanded: _vm.expanded,
                            model: _vm.model,
                            root: _vm.rootNode
                          }
                        )
                      ],
                      2
                    ),
                !_vm.editable
                  ? _c(
                      "div",
                      {
                        staticClass: "vtl-node-content",
                        style: { fontWeight: _vm.model.isLeaf ? null : "bold" }
                      },
                      [
                        _vm._t(
                          "leafNameDisplay",
                          [_vm._v(" " + _vm._s(_vm.model.name) + " ")],
                          {
                            expanded: _vm.expanded,
                            model: _vm.model,
                            root: _vm.rootNode
                          }
                        )
                      ],
                      2
                    )
                  : _c("input", {
                      ref: "nodeInput",
                      staticClass: "vtl-input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.model.name },
                      on: { input: _vm.updateName, blur: _vm.setUnEditable }
                    }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isHover,
                        expression: "isHover"
                      }
                    ],
                    staticClass: "vtl-operation"
                  },
                  [
                    !_vm.model.isLeaf &&
                    !_vm.model.addTreeNodeDisabled &&
                    _vm.isValidDepth
                      ? _c(
                          "span",
                          {
                            attrs: { title: _vm.$t("Add Tree Node") },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.addChild(false)
                              }
                            }
                          },
                          [
                            _vm._t(
                              "addTreeNodeIcon",
                              [
                                _c("i", {
                                  staticClass: "vtl-icon vtl-icon-folder-plus-e"
                                })
                              ],
                              {
                                expanded: _vm.expanded,
                                model: _vm.model,
                                root: _vm.rootNode
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    !_vm.model.isLeaf && !_vm.model.addLeafNodeDisabled
                      ? _c(
                          "span",
                          {
                            attrs: { title: _vm.$t("Add Leaf Node") },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.addChild(true)
                              }
                            }
                          },
                          [
                            _vm._t(
                              "addLeafNodeIcon",
                              [
                                _c("i", {
                                  staticClass: "vtl-icon vtl-icon-plus"
                                })
                              ],
                              {
                                expanded: _vm.expanded,
                                model: _vm.model,
                                root: _vm.rootNode
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    !_vm.model.editNodeDisabled
                      ? _c(
                          "span",
                          {
                            attrs: { title: _vm.$t("editCategory") },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.setEditable($event)
                              }
                            }
                          },
                          [
                            _vm._t(
                              "editNodeIcon",
                              [
                                _c("i", {
                                  staticClass: "vtl-icon vtl-icon-edit"
                                })
                              ],
                              {
                                expanded: _vm.expanded,
                                model: _vm.model,
                                root: _vm.rootNode
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    !_vm.model.delNodeDisabled
                      ? _c(
                          "span",
                          {
                            attrs: { title: _vm.$t("deleteCategory") },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.delNode($event)
                              }
                            }
                          },
                          [
                            _vm._t(
                              "delNodeIcon",
                              [
                                _c("i", {
                                  staticClass: "vtl-icon vtl-icon-trash"
                                })
                              ],
                              {
                                expanded: _vm.expanded,
                                model: _vm.model,
                                root: _vm.rootNode
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm.model.children && _vm.model.children.length > 0 && _vm.expanded
              ? _c("div", {
                  staticClass: "vtl-border vtl-bottom",
                  class: { "vtl-active": _vm.isDragEnterBottom },
                  on: {
                    drop: _vm.dropAfter,
                    dragenter: _vm.dragEnterBottom,
                    dragover: _vm.dragOverBottom,
                    dragleave: _vm.dragLeaveBottom
                  }
                })
              : _vm._e()
          ]
        )
      : _vm._e(),
    _vm.isFolder
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.model.name === "root" || _vm.expanded,
                expression: "model.name === 'root' || expanded"
              }
            ],
            class: { "vtl-tree-margin": _vm.model.name !== "root" }
          },
          _vm._l(_vm.model.children, function(model) {
            return _c("item", {
              key: model.id,
              attrs: {
                "default-tree-node-name": _vm.defaultTreeNodeName,
                "default-leaf-node-name": _vm.defaultLeafNodeName,
                "default-expanded": _vm.defaultExpanded,
                "is-fold": _vm.isFold,
                model: model
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "leafNameDisplay",
                    fn: function(slotProps) {
                      return [_vm._t("leafNameDisplay", null, null, slotProps)]
                    }
                  },
                  {
                    key: "addTreeNodeIcon",
                    fn: function(slotProps) {
                      return [_vm._t("addTreeNodeIcon", null, null, slotProps)]
                    }
                  },
                  {
                    key: "addLeafNodeIcon",
                    fn: function(slotProps) {
                      return [_vm._t("addLeafNodeIcon", null, null, slotProps)]
                    }
                  },
                  {
                    key: "editNodeIcon",
                    fn: function(slotProps) {
                      return [_vm._t("editNodeIcon", null, null, slotProps)]
                    }
                  },
                  {
                    key: "delNodeIcon",
                    fn: function(slotProps) {
                      return [_vm._t("delNodeIcon", null, null, slotProps)]
                    }
                  },
                  {
                    key: "leafNodeIcon",
                    fn: function(slotProps) {
                      return [_vm._t("leafNodeIcon", null, null, slotProps)]
                    }
                  },
                  {
                    key: "treeNodeIcon",
                    fn: function(slotProps) {
                      return [_vm._t("treeNodeIcon", null, null, slotProps)]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }