//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TreeNode } from './Tree.js';
import { addHandler, removeHandler } from './tools.js';
import { LIMIT_DROP, LIMIT_LEVEL, LIMIT_MAX_ITEM } from './constants';
let compInOperation = null;

export default {
  name: 'vue-tree-list',
  data: function() {
    return {
      isHover: false,
      editable: false,
      isDragEnterUp: false,
      isDragEnterBottom: false,
      isDragEnterNode: false,
      expanded: this.defaultExpanded
    };
  },
  props: {
    model: {
      type: Object
    },
    defaultLeafNodeName: {
      type: String,
      default: 'Leaf Node'
    },
    defaultTreeNodeName: {
      type: String,
      default: 'Tree Node'
    },
    defaultAddTreeNodeTitle: {
      type: String,
      default: 'Add Tree Node'
    },
    defaultAddLeafNodeTitle: {
      type: String,
      default: 'Add Leaf Node'
    },
    defaultExpanded: {
      type: Boolean,
      default: true
    },
    limitLevel: {
      type: Number,
      default: 3
    },
    limitMaxItem: {
      type: Number,
      default: 12
    },
    isFold: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    rootNode() {
      var node = this.$parent;
      while (node._props.model.name !== 'root') {
        node = node.$parent;
      }
      return node;
    },
    isValidDepth() {
      var node = this.$parent;
      let length = 1;
      while (node._props.model.name !== 'root') {
        node = node.$parent;
        length++;
      }
      return length < node._props.limitLevel;
    },

    caretClass() {
      return this.expanded ? 'vtl-icon-caret-down' : 'vtl-icon-caret-right';
    },

    isFolder() {
      return this.model.children && this.model.children.length;
    },

    treeNodeClass() {
      const {
        model: { dragDisabled, disabled },
        isDragEnterNode
      } = this;

      return {
        'vtl-node-main': true,
        'vtl-active': isDragEnterNode,
        'vtl-drag-disabled': dragDisabled,
        'vtl-disabled': disabled
      };
    }
  },
  beforeCreate() {
    this.$options.components.item = require('./VueTreeList').default;
  },
  mounted() {
    const vm = this;
    addHandler(window, 'keyup', function(e) {
      // click enter
      if (e.keyCode === 13 && vm.editable) {
        vm.editable = false;
      }
    });
  },
  beforeDestroy() {
    removeHandler(window, 'keyup');
  },
  methods: {
    updateName(e) {
      var oldName = this.model.name;
      this.model.changeName(e.target.value);
      this.rootNode.$emit('change-name', {
        id: this.model.id,
        oldName: oldName,
        newName: e.target.value,
        node: this.model
      });
    },

    delNode() {
      this.rootNode.$emit('delete-node', this.model);
    },

    setEditable() {
      this.editable = true;
      this.$nextTick(() => {
        const $input = this.$refs.nodeInput;
        $input.focus();
        $input.setSelectionRange(0, $input.value.length);
      });
    },

    setUnEditable(e) {
      this.editable = false;
      var oldName = this.model.name;
      this.model.changeName(e.target.value);
      this.rootNode.$emit('change-name', {
        id: this.model.id,
        oldName: oldName,
        newName: e.target.value,
        eventType: 'blur'
      });
    },

    toggle() {
      if (this.isFolder) {
        this.expanded = !this.expanded;
      }
    },

    mouseOver() {
      if (this.model.disabled) return;
      this.isHover = true;
    },

    mouseOut() {
      this.isHover = false;
    },

    click() {
      this.rootNode.$emit('click', {
        toggle: this.toggle,
        ...this.model
      });
    },

    addChild(isLeaf) {
      const name = isLeaf ? this.defaultLeafNodeName : this.defaultTreeNodeName;
      this.expanded = true;
      if (this.model.children?.length >= this.rootNode._props.limitMaxItem) {
        this.rootNode.$emit('add-node', this.errorResponse(LIMIT_MAX_ITEM));
        return;
      }
      var node = new TreeNode({ name, isLeaf });
      this.model.addChildren(node, true);
      this.rootNode.$emit('add-node', node);
    },

    dragStart(e) {
      if (!(this.model.dragDisabled || this.model.disabled)) {
        compInOperation = this;
        // for firefox
        e.dataTransfer.setData('data', 'data');
        e.dataTransfer.effectAllowed = 'move';
        return true;
      }
      return false;
    },
    dragEnd() {
      compInOperation = null;
    },
    dragOver(e) {
      e.preventDefault();
      return true;
    },
    dragEnter() {
      if (!compInOperation) return;
      if (compInOperation.model.id === this.model.id || !compInOperation || this.model.isLeaf)
        return;
      this.isDragEnterNode = true;
    },
    dragLeave() {
      this.isDragEnterNode = false;
    },
    traceBackDepth(node) {
      let length = 0;
      while (node.name !== 'root') {
        node = node.parent;
        length++;
      }
      return length;
    },
    findDepth(node) {
      var maxDepth = 0;
      if (node.children && node.children.length) {
        var depth = 0;
        for (let i = 0; i < node.children.length; i++) {
          let child = node.children[i];
          depth = this.findDepth(child) + 1;
          maxDepth = depth > maxDepth ? depth : maxDepth;
        }
      }
      return maxDepth;
    },
    errorResponse(type) {
      switch (type) {
        case LIMIT_DROP:
          return {
            error: {
              type: LIMIT_DROP,
              message: this.$t('Parent category must have at least 1 item!')
            }
          };
        case LIMIT_LEVEL:
          return {
            error: {
              type: LIMIT_LEVEL,
              message: this.$t('Must be 3 levels maximum!')
            }
          };
        case LIMIT_MAX_ITEM:
          return {
            error: {
              type: LIMIT_MAX_ITEM,
              message: this.$t('Must be 12 item maximum!')
            }
          };
        default:
          break;
      }
    },
    drop() {
      if (!compInOperation || compInOperation.model == this.model) return;
      const oldParent = compInOperation.model.parent;
      if (this.model.children?.length >= this.rootNode._props.limitMaxItem) {
        this.rootNode.$emit('drop', this.errorResponse(LIMIT_MAX_ITEM));
        return;
      }
      let depthNode = this.findDepth(compInOperation.model);
      let depthTarget = this.traceBackDepth(this.model);
      if (depthNode + depthTarget > this.rootNode._props.limitLevel) {
        this.rootNode.$emit('drop', this.errorResponse(LIMIT_LEVEL));
        return;
      }
      compInOperation.model.moveInto(this.model);
      this.isDragEnterNode = false;
      this.rootNode.$emit('drop', {
        target: this.model,
        node: compInOperation.model,
        src: oldParent
      });
    },

    dragEnterUp() {
      if (!compInOperation) return;
      this.isDragEnterUp = true;
    },
    dragOverUp(e) {
      e.preventDefault();
      return true;
    },
    dragLeaveUp() {
      if (!compInOperation) return;
      this.isDragEnterUp = false;
    },
    dropBefore() {
      if (!compInOperation) return;
      const oldParent = compInOperation.model.parent;
      if (
        this.model.parent !== compInOperation.model.parent &&
        this.model.parent.children?.length >= this.rootNode._props.limitMaxItem
      ) {
        this.rootNode.$emit('drop-before', this.errorResponse(LIMIT_MAX_ITEM));
        return;
      }
      let depthNode = this.findDepth(compInOperation.model);
      let depthTarget = this.traceBackDepth(this.model) - 1;
      if (depthNode + depthTarget > this.rootNode._props.limitLevel) {
        this.rootNode.$emit('drop-before', this.errorResponse(LIMIT_LEVEL));
        return;
      }
      compInOperation.model.insertBefore(this.model);
      this.isDragEnterUp = false;
      this.rootNode.$emit('drop-before', {
        target: this.model,
        node: compInOperation.model,
        src: oldParent
      });
    },

    dragEnterBottom() {
      if (!compInOperation) return;
      this.isDragEnterBottom = true;
    },
    dragOverBottom(e) {
      e.preventDefault();
      return true;
    },
    dragLeaveBottom() {
      if (!compInOperation) return;
      this.isDragEnterBottom = false;
    },
    dropAfter() {
      if (!compInOperation) return;
      const oldParent = compInOperation.model.parent;
      if (this.model.parent.children?.length >= this.rootNode._props.limitMaxItem) {
        this.rootNode.$emit('drop-after', this.errorResponse(LIMIT_MAX_ITEM));
        return;
      }
      let depthNode = this.findDepth(compInOperation.model);
      let depthTarget = this.traceBackDepth(this.model) - 1;
      if (depthNode + depthTarget > this.rootNode._props.limitLevel) {
        this.rootNode.$emit('drop-after', this.errorResponse(LIMIT_LEVEL));
        return;
      }
      compInOperation.model.insertAfter(this.model);
      this.isDragEnterBottom = false;
      this.rootNode.$emit('drop-after', {
        target: this.model,
        node: compInOperation.model,
        src: oldParent
      });
    }
  },

  watch: {
    isFold: {
      handler(value) {
        if (this.isFolder) {
          this.expanded = !value;
        }
      },
      immediate: true
    }
  }
};
