var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        {
          staticClass: "mb-sm",
          attrs: { gutter: [16, 16], type: "flex", justify: "space-between" }
        },
        [
          _c(
            "div",
            { staticStyle: { width: "30%" } },
            [
              _c("a-button", { on: { click: _vm.addNode } }, [
                _vm._v(_vm._s(_vm.$t("Add Root Category")))
              ])
            ],
            1
          ),
          _vm._t("headerLeft")
        ],
        2
      ),
      _vm.foldAble
        ? _c(
            "div",
            { staticClass: "mb-xs" },
            [
              _c(
                "a-button",
                {
                  attrs: { icon: _vm.isFold ? "caret-down" : "caret-right" },
                  on: { click: _vm.onFold }
                },
                [_vm._v(_vm._s(_vm.isFold ? _vm.$t("Unfold") : _vm.$t("Fold")))]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-row",
        [
          _c(
            "vue-tree-list",
            {
              style: Object.assign({}, _vm.currentGlobalStyle),
              attrs: {
                model: _vm.data,
                "default-tree-node-name": _vm.$t("New node"),
                "default-leaf-node-name": _vm.$t("New leaf"),
                "default-expanded": true,
                limitLevel: _vm.limitLevel,
                limitMaxItem: _vm.limitMaxItem,
                "is-fold": _vm.isFold
              },
              on: {
                click: _vm.onClick,
                "change-name": _vm.onChangeName,
                "delete-node": _vm.showConfirmDelNode,
                "add-node": _vm.onAddNode,
                drop: _vm.onDropNode,
                "drop-before": _vm.onDropBeforeNode
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "icon",
                  attrs: { slot: "leafNodeIcon" },
                  slot: "leafNodeIcon"
                },
                [_vm._v("📄")]
              ),
              _c(
                "span",
                {
                  staticClass: "icon",
                  attrs: { slot: "treeNodeIcon" },
                  slot: "treeNodeIcon"
                },
                [_vm._v("📁")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }