//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { VueTreeList, Tree, TreeNode } from './TreeView/index';
import { mixin as langMixin } from '@/mixins/i18n-mixin';

export default {
  components: {
    VueTreeList
  },
  props: {
    // treeData:{
    //   type: Array
    // },
    pageId: String
  },
  mixins: [langMixin],
  data() {
    return {
      newTree: {},
      data: new Tree([]),
      limitLevel: 3,
      limitMaxItem: 50,
      isFold: false,
      foldAble: false
    };
  },
  async created() {
    try {
      this.loading = true;
      await this.fetchCategories(this.pageId);
      this.data?.children?.map(node => {
        if (node.children?.length > 0) this.foldAble = true;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters('category', ['originCategories'])
  },
  watch: {
    originCategories: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.data = new Tree(newVal);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('category', ['fetchCategories']),

    async onChangeName(params) {
      const { id, newName, eventType } = params;
      if (newName === 'root') {
        this.$message.error(
          this.$t('Root Category name has been used, please change another name')
        );
        return;
      }
      try {
        if (eventType && eventType === 'blur') {
          let data = {
            name: newName
          };
          await this.$s.api.page.updateCategory(this.pageId, id, data);
          this.fetchCategories(this.pageId); // Reload category store
          this.notify();
        }
      } catch (error) {
        this.notify('error', error);
      }
      this.$emit('change-name', params);
    },
    searchTree(element, id) {
      if (element.id == id) {
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchTree(element.children[i], id);
        }
        return result;
      }
      return null;
    },
    searchAsideNode(element, asideElement, id) {
      if (element.id == id) {
        return asideElement;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchAsideNode(element.children[i], element.children[i - 1], id);
        }
        return result;
      }
      return null;
    },
    async onAddNode(params) {
      try {
        if (params.error) {
          this.notify('error', params.error.message);
          return;
        }
        let { id, name, isLeaf, parent } = params;
        let data = {
          name,
          isLeaf,
          ...(parent.id === 0 ? null : { parent: parent.id })
        };
        let result = await this.$s.api.page.createCategory(this.pageId, data);
        this.fetchCategories(this.pageId); // Reload category store
        let node = this.searchTree(this.data, id);
        node.id = result.id;
        node.orderBy = result.orderBy;
        this.notify();
        this.$emit('add-node', params);
      } catch (error) {
        this.notify('error', error);
      }
    },

    onClick(params) {
      this.$emit('click', params);
    },

    onFold() {
      this.isFold = !this.isFold;
    },

    addNode() {
      if (this.data.children?.length >= this.limitMaxItem) {
        this.onAddNode({
          error: {
            message: this.$t('Must be 50 item maximum!')
          }
        });
        return;
      }

      var node = new TreeNode({ name: this.$t('New node'), isLeaf: false });
      if (!this.data.children) this.data.children = [];
      this.data.addChildren(node);
      this.onAddNode(node);
    },

    async onDropNode(params) {
      try {
        let { node, target, error } = params;
        if (error) {
          this.notify('error', error.message);
          return;
        }
        if (this.validateMoveInto(node, target)) {
          let data = {
            parent: target.id === 0 ? null : target.id,
            orderBy:
              target.children && target.children.length > 1
                ? target.children[1].orderBy - 0.5
                : node.orderBy
          };
          await this.$s.api.page.updateCategory(this.pageId, node.id, data);
          this.fetchCategories(this.pageId); // Reload category store
          node.orderBy = data.orderBy;
          this.notify();
          this.$emit('drop', params);
        }
      } catch (error) {
        this.notify('error', error);
        console.log(error);
      }
    },
    validateMoveInto(node, target) {
      if (node.name === 'root' || node === target) {
        return;
      }

      // cannot move ancestor to child
      if (node.isTargetChild(target)) {
        return;
      }

      // cannot move to leaf node
      if (target.isLeaf) {
        return;
      }
      return true;
    },
    async onDropBeforeNode(params) {
      try {
        let { node, target, error } = params;
        if (error) {
          this.notify('error', error.message);
          return;
        }

        let data = {
          parent: target.pid === 0 ? null : target.pid
        };
        let asideNode = this.searchAsideNode(this.data, null, node.id);
        if (asideNode) data.orderBy = (target.orderBy + asideNode.orderBy) / 2;
        else data.orderBy = target.orderBy - 0.5;

        await this.$s.api.page.updateCategory(this.pageId, node.id, data);
        this.fetchCategories(this.pageId); // Reload category store
        node.orderBy = data.orderBy;
        this.notify();
        this.$emit('drop', params);
      } catch (error) {
        this.notify('error', error);
        console.log(error);
      }
    },
    async onDel(node) {
      try {
        //node is the class, using toString build-in fuction to get object tring, then using parse to convert to Object
        await this.$s.api.page.destroyCategoryBranch(this.pageId, JSON.parse(node.toString()));
        this.fetchCategories(this.pageId); // Reload category store
        this.notify();
        this.$emit('delete-node', node);
        node.remove();
      } catch (error) {
        this.notify('error', error);
        console.log(error);
      }
    },
    async onCheckExistCodeInCategory(node) {
      try {
        let check = await this.$s.api.page.checkExistCodeInCategoryBranch(
          this.pageId,
          JSON.parse(node.toString())
        );
        return check;
      } catch (error) {
        console.log(error);
      }
    },
    async showConfirmDelNode(node) {
      let vm = this;
      this.$confirm({
        title: vm.$t('Do you want to delete this Category?'),
        // content: 'When clicked the OK button, this dialog will be closed after 1 second',
        async onOk() {
          if (await vm.onCheckExistCodeInCategory(node)) {
            const h = vm.$createElement;
            vm.$confirm({
              title: vm.$t('Warning'),
              content: h('div', {}, [
                h('p', vm.$tc('confirm delete', 0)),
                h('p', vm.$tc('confirm delete', 1))
              ]),
              async onOk() {
                await vm.onDel(node);
              },
              onCancel() {}
            });
          } else await vm.onDel(node);
        },
        onCancel() {}
      });
    },
    getNewTree() {
      var vm = this;
      function _dfs(oldNode) {
        var newNode = {};

        for (var k in oldNode) {
          if (k !== 'children' && k !== 'parent') {
            newNode[k] = oldNode[k];
          }
        }

        if (oldNode.children && oldNode.children.length > 0) {
          newNode.children = [];
          for (var i = 0, len = oldNode.children.length; i < len; i++) {
            newNode.children.push(_dfs(oldNode.children[i]));
          }
        }
        return newNode;
      }

      vm.newTree = _dfs(vm.data);
    },
    notify(type = 'success', error = null) {
      const config = {
        success: () => {
          return {
            type: 'success',
            message: this.$t('Successful')
          };
        },
        error: error => {
          return { type: 'error', message: error };
        }
      };
      this.$notification[type](config[type](error));
    }
  }
};
